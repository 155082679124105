<template>
  <b-card>
      <pocu-header v-bind:show_add_new="false" v-bind:show_project_select="false"></pocu-header>
      <b-row>
        <b-col cols="12" sm="12" md="6" lg="4" xl="4" style="margin:auto;margin-top:50px;">
          <div class="add_new_content">
            <b-img
              class="smile_img"
              :src="
                require('@/assets/images/pocu/smile.png')
              "
            />
            <p class="intro_text">Salut! Mulțumim că ai ales POCU<strong>manager</strong></p>
            <p class="intro_subtext">Te rugăm să introduci codul MySMIS al proiectului</p>
            <b-row>
              <b-col cols="6">
                <b-form-input  type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxLength="6" @keydown.enter.native="validateIdProiect" id="new_project_id" placeholder="Cod MySMIS" v-model="new_project_id" />
              </b-col>
              <b-col cols="6">
                <b-button variant="primary" @click="validateIdProiect" block>
                    Validează
                  </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
  </b-card>
</template>

<script>
import axios from '@axios'
import {
  BRow, BCol, BCard, BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BFormCheckboxGroup, BIconNutFill, BSpinner,  BImg
} from 'bootstrap-vue'
import PocuHeader from './parts/PocuHeader.vue'
import {mapState} from 'vuex'

export default {
  components:{
    BRow, 
    BCol, 
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormInput,
    PocuHeader,
    BImg
  },
  data() {
    return {
      new_project_id:'',
    }
  },
  computed: {
    ...mapState('pocu',{
      currentProjectId : 'project_id',
      allProjects: 'projects'
    }),
    currentProject(){
      return this.allProjects[this.currentProjectId]
    },
  },
  watch: {

  },
  methods: {
    updateValue(value){
      if (String(value).length <= 6){
        this.new_project_id = value
      }
      this.$forceUpdate()
    },
    newProjectIdKeyPressed(event){
      if (event.which == 13){
        validateIdProiect()
      }
    },
    validateIdProiect(){
      //TODO validate id length
      axios
        .get('/pocu/validateProjectId',{
          params: {
            id_proiect : this.new_project_id
          }
        })
        .then(response => {
          if (response.data.success){
            this.setCurrentProjectId(response.data.project_internal_id)
            this.setAllProjects(response.data.all_projects)
            //redirect to project page
            this.$router.push({name:'pocu-proiectul-meu'});
          }
        })
        .catch(error => {
          // console.log(error)
        })
    },
    setCurrentProjectId(project_id){
      this.$store.commit('pocu/SET_CURRENT_PROJECT_ID',project_id)
    },
    setAllProjects(projects){
      this.$store.commit('pocu/SET_PROJECTS',projects)
    }
  },
  mounted(){

  },
  created(){
    
  },
}
</script>

<style scoped>
.add_new_content{
  text-align: center;
  max-width: 600px;
}
#new_project_id{
  -moz-appearance: textfield;
}
.smile_img{
  margin-bottom:40px;
}
.intro_text{
  font-size: 45px;
  line-height: 50px;
  margin-bottom:50px;
}
.intro_subtext{
  margin-bottom:50px;
}
#new_project_id{
  font-weight: 900;
  font-size: 22px;
  background-color:#fff;
  letter-spacing: 5px;
  text-align:center;
}
</style>