<template>
  <div>
    <b-row>
     <b-col cols="12" sm="6" md="4" lg="3" xl="2" >
        <b-form-group v-if="show_project_select"> 
          <v-select
            v-model="selectedProject"
            filled
            label="nume"
            :options="selectProjectItems"
            placeholder="Alege Proiect"
            v-bind:searchable="false"
            v-bind:clearable="false"
           >
            <div slot="no-options">Nu ai nici un proiect!</div>
            </v-select>
          </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" md="5" lg="4" xl="3" >
        <p style="margin-top:5px;"><a class="add_new_project" v-on:click.prevent="addNewProject()" v-if="show_add_new">Adaugă un proiect nou <plus-circle-icon size="1.5x"></plus-circle-icon></a></p>
       </b-col>
       <b-col>
         <b-img
              class="pocu_manager_logo"
              :src="
                require('@/assets/images/pocu/pocu_manager_logo.png')
              "
            />
       </b-col>
    </b-row>
    <hr>
  </div>
</template>

<script>
import axios from '@axios'
import {
  BRow, BCol, BCard, BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BFormCheckboxGroup, BIconNutFill, BSpinner, BImg
} from 'bootstrap-vue'
import { PlusCircleIcon } from 'vue-feather-icons'
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
import {mapState} from 'vuex'

export default {
  components:{
    BRow,
    BCol,
    BCard,
    PlusCircleIcon,
    vSelect,
    BFormGroup,
    BImg
  },
  data() {
    return {
      selectedProject:[],
      selectProjectItems:[],
    }
  },
  props: {
    show_add_new : {
      type: Boolean,
      default: true
    },
    show_project_select: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('pocu',{
      currentProjectId : 'project_id',
      allProjects: 'projects'
    }),
    currentProject(){
      return this.allProjects[this.currentProjectId] ? this.allProjects[this.currentProjectId] : null
    },
  },
  mounted(){

  },
  watch: {
    currentProjectId(newVal,oldVal){
      if (!newVal){return}
      if ((newVal && !this.selectedProject) || (newVal != this.selectedProject.value)){
        if (newVal in this.allProjects){
          this.selectedProject = {
            value:newVal,
            nume:"POCU " + this.allProjects[newVal].id_proiect
          }
        }
      }
    },
    allProjects:{
      deep:true,
      handler(newVal,oldVal){
        let newOptions = []
        let keys = Object.keys(newVal)
        keys.forEach(key => {
          newOptions.push({
            value:key,
            nume:"POCU " + newVal[key].id_proiect
          })
        })
        this.selectProjectItems = newOptions
        //Check if have selected project
        if (!this.currentProject || !(this.currentProject.value in newVal) || !(this.selectedProject)){
          if (this.currentProjectId){
            this.selectedProject = {
              value:this.currentProjectId,
              nume:"POCU " + this.allProjects[this.currentProjectId].id_proiect
            }
          } else {
            this.selectedProject = newOptions[0]
          }
        }
      },
    },
    selectedProject(newVal, oldVal){
      if (newVal && newVal.value != this.currentProject){
        this.setCurrentProjectId(newVal.value)
      }
    }
  },
  methods: {
    addNewProject(){
      this.$router.push({name:'pocu-proiectul-meu-adauga'});
    },
    setCurrentProjectId(project_id){
      this.$store.commit('pocu/SET_CURRENT_PROJECT_ID',project_id)
    },
    setAllProjects(projects){
      this.$store.commit('pocu/SET_PROJECTS',projects)
    },
    updateProject(project){
      this.$store.commit('pocu/UPDATE_PROJECT',project)
    },
    loadAllProjects(){
      axios.get('/pocu/getProjects')
        .then(response => {
          if (response.data.success){
            let projects = {}
            for (let i of response.data.items){
              projects[i.id] = i
            }
            this.setAllProjects(projects)
          }
        })
    },
  },
  created(){
    //Load all projects
    this.loadAllProjects()
  },
}
</script>

<style scoped>
.add_new_project{
  color: #3ceed4;
  font-size:18px;
}
.pocu_manager_logo{
  float:right;
}
</style>